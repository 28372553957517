import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { userTerms } from "../../src/components/styles/UserTerms.styles"

const SecondPage = () => (
  <Layout>
    <SEO title="Aqui você encontra conteúdo com foco em alta performance" />
    <div css={userTerms}>
      <h1>Termos e Condições de Uso do Aplicativo Banco de Questões</h1>
      <p>A seguir estão descritas as regras aplicáveis à utilização do aplicativo “Banco de Questões” (“Aplicativo”) desenvolvido e disponibilizado pela Fractal Tecnologia e Participações S.A., inscrita no CNPJ sob o n. 26.900.128/0001-03, denominada simplesmente “Fractal”, em dispositivos móveis que oferece, em um ambiente virtual, questões para resolução aos seus usuários (“Usuário”).</p>
      <p>Ao realizar o cadastro para utilização do Aplicativo, o Usuário se submeterá automaticamente a todas as regras e condições destes Termos de Uso.</p>
      <p>O Usuário fará seu cadastro no primeiro acesso ao Aplicativo e deverá escolher uma das programações disponíveis podendo vincular seu cadastro a seus perfis de outras redes sociais.</p>
      <p>Será permitido um único cadastramento por Usuário, devendo o acesso, visualização e uso do Aplicativo ser feito pelo Usuário em caráter pessoal e intransferível.</p>
      <p>A Fractal se reserva o direito de, sem aviso prévio, cancelar e/ou bloquear o acesso do Usuário, a qualquer momento, caso seja constatado que este praticou algum ato ou mantenha conduta que (i) viole as leis e regulamentos federais, estaduais e/ou municipais; (ii) contrarie as regras destes Termos de Uso, e (ii) viole os princípios da moral e dos bons costumes.</p>
      <p>Toda e qualquer ação executada ou conteúdo publicado pelo Usuário durante o uso do Aplicativo será de sua exclusiva e integral responsabilidade, devendo isentar a Fractal de quaisquer reclamações judiciais ou extrajudiciais, prejuízos, perdas e danos causados, em decorrência de tais ações ou manifestações.</p>
      <p>O Usuário, desde já, autoriza a Fractal ou terceiros por ele indicados, a utilizar, por prazo indeterminado, as informações disponibilizadas quando de seu cadastro e durante o uso do Aplicativo para fins estatísticos, envio de material publicitário, newsletters, informes ou qualquer outra utilização a seu exclusivo critério.</p>
      <p>A Fractal se reserva o direito de incluir, excluir ou alterar quaisquer aspectos e conteúdo do Aplicativo, podendo suspendê-lo temporariamente ou cancelá-lo, a seu exclusivo critério e a qualquer tempo, independente de aviso prévio ao Usuário. Da mesma forma, a Fractal poderá modificar estes Termos de Uso, cuja versão mais recente estará sempre disponível para consulta no próprio Aplicativo.</p>
      <p>A Fractal se exime de toda e qualquer responsabilidade pelos danos e prejuízos de qualquer natureza que possam decorrer do acesso, interceptação, eliminação, alteração, modificação ou manipulação, por terceiros não autorizados, dos dados do Usuário durante a utilização do aplicativo.</p>
      <p>O Usuário declara ter lido, estar ciente e de pleno acordo com o conteúdo e condições deste Termo. Fica eleito o Foro da Comarca da Cidade de São Paulo, no Estado de São Paulo, para dirimir quaisquer questões oriundas destes Termos de Uso, que será regido pelas leis brasileiras, desistindo as Partes de qualquer outro, por mais privilegiado que seja. Qualquer disputa que surja em virtude do presente Termo será regulada pela legislação da República Federativa do Brasil.</p>
      <Link to="/">Voltar para a página inicial</Link>
    </div>
  </Layout>
)

export default SecondPage